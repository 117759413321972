import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';

declare type HttpObserve = 'body' | 'events' | 'response';
export const resp: HttpObserve = 'response';

export const httpObserveOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	}),
	params: {},
	observe: resp,
};

export abstract class ApiService<D = unknown> {
	public static DEFAULT_PROTOCOL = 'http';
	public static DEFAULT_HOST = 'localhost';
	public static DEFAULT_PORT = 8090;

	private readonly apiEndpoint: string;
	protected abstract prefix: string;

	protected constructor(protected http: HttpClient) {
		this.apiEndpoint = `${environment.api.protocol}://${environment.api.host}:${environment.api.port}`;
	}

	public static handleError(error: HttpErrorResponse) {
		if (error.status === 0) {
			console.error('An error occurred:', error.error);
		} else {
			console.error(`Backend returned code ${error.status}, body was: `, error.error);
		}
		return throwError(() => new Error(error.message));
	}

	public callHead<R = D>(route: string, httpGetOptions = httpObserveOptions): Observable<HttpResponse<R>> {
		return this.http
			.head<R>(`${this.apiEndpoint}/${route}`, httpGetOptions)
			.pipe(catchError(ApiService.handleError));
	}

	public callGet<R = D>(route: string, httpGetOptions = httpObserveOptions): Observable<HttpResponse<R>> {
		return this.http
			.get<R>(`${this.apiEndpoint}/${route}`, httpGetOptions)
			.pipe(catchError(ApiService.handleError));
	}

	public callPost<P, R = D>(
		route: string,
		data: P,
		httpPostOptions = httpObserveOptions
	): Observable<HttpResponse<R>> {
		return this.http
			.post<R>(`${this.apiEndpoint}/${route}`, data, httpPostOptions)
			.pipe(catchError(ApiService.handleError));
	}

	public callPut<P, R = D>(route: string, data: P, httpPutOptions = httpObserveOptions): Observable<HttpResponse<R>> {
		return this.http
			.put<R>(`${this.apiEndpoint}/${route}`, data, httpPutOptions)
			.pipe(catchError(ApiService.handleError));
	}

	public callPatch<P, R = D>(
		route: string,
		data: P,
		httpPatchOptions = httpObserveOptions
	): Observable<HttpResponse<R>> {
		return this.http
			.patch<R>(`${this.apiEndpoint}/${route}`, data, httpPatchOptions)
			.pipe(catchError(ApiService.handleError));
	}

	public callDelete<B>(route: string, httpDeleteOptions = httpObserveOptions): Observable<HttpResponse<B>> {
		return this.http
			.delete<B>(`${this.apiEndpoint}/${route}`, httpDeleteOptions)
			.pipe(catchError(ApiService.handleError));
	}
}
