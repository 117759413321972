<img
	*ngIf="image"
	class="inline-block h-10 w-10 rounded-md"
	[src]="image"
	alt="user-avatar-image" />

<div
	*ngIf="!image"
	class="flex size-10 items-center justify-center rounded-full bg-gray-700 text-white">
	{{ initials }}
</div>
