import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IChatAPI, IChatQueryAPI, IChatResponseAPI, ILastChatsApi } from '../../types/api/api-chat.types';
import { map, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ApiChatService extends ApiService<IChatAPI> {
	protected prefix: string = 'chats';
	constructor(protected override http: HttpClient) {
		super(http);
	}

	public getLastChats(): Observable<ILastChatsApi> {
		return this.callGet<ILastChatsApi>(`${this.prefix}/list`).pipe(
			map((apiResponse: HttpResponse<ILastChatsApi>) => {
				return apiResponse.body as ILastChatsApi;
			})
		);
	}

	public getChats() {
		return this.callGet<Array<IChatAPI>>(this.prefix).pipe(
			map((apiResponse: HttpResponse<Array<IChatAPI>>) => {
				return apiResponse.body as Array<IChatAPI>;
			})
		);
	}

	public getChat(uuid: string) {
		return this.callGet<IChatAPI>(`${this.prefix}/${uuid}`).pipe(
			map((apiResponse: HttpResponse<IChatAPI>) => {
				return apiResponse.body as IChatAPI;
			})
		);
	}

	public addChat() {
		return this.callPost<null, string>(this.prefix, null).pipe(
			map((apiResponse: HttpResponse<string>) => {
				return apiResponse.body as string;
			})
		);
	}

	public deleteChat(uuid: string) {
		return this.callDelete<null>(`${this.prefix}/${uuid}`).pipe(
			map(() => {
				return;
			})
		);
	}

	public createQuery(chatUUID: string, prompt: string): Observable<IChatQueryAPI> {
		return this.callPost<{ prompt: string }, IChatQueryAPI>(`${this.prefix}/${chatUUID}/query`, {
			prompt: prompt,
		}).pipe(
			map((apiResponse: HttpResponse<IChatQueryAPI>) => {
				return apiResponse.body as IChatQueryAPI;
			})
		);
	}

	public getResponse(responseUUID: string): Observable<IChatResponseAPI> {
		return this.callGet<IChatResponseAPI>(`${this.prefix}/response/${responseUUID}`).pipe(
			map((apiResponse: HttpResponse<IChatResponseAPI>) => {
				return apiResponse.body as IChatResponseAPI;
			})
		);
	}

	public createResponse(chatUUID: string, responseUUID: string): Observable<IChatResponseAPI> {
		return this.callPost<null, IChatResponseAPI>(`${this.prefix}/${chatUUID}/query/${responseUUID}/response`, null).pipe(
			map((apiResponse: HttpResponse<IChatResponseAPI>) => {
				return apiResponse.body as IChatResponseAPI;
			})
		);
	}

	public updateResponseReaction(responseUUID: string, reaction: number | null): Observable<void> {
		return this.callPost<{ quality: number | null }, void>(`${this.prefix}/response/${responseUUID}/reaction`, {
			quality: reaction,
		}).pipe(
			map(() => {
				return;
			})
		);
	}
}
