import { Component, Input } from '@angular/core';

@Component({
	selector: 'dwa-bot-avatar',
	templateUrl: './bot-avatar.component.html',
	styleUrl: './bot-avatar.component.scss',
})
export class BotAvatarComponent {
	@Input() public bgStyleClass = 'bg-gray-200';
	@Input() public avatarSizeClass: 'size-10' | 'size-12' | 'size-16' | 'size-20' = 'size-10';
	@Input() public iconSize: number = 16;

	public get viewBox(): string {
		return `0 0 ${this.iconSize} ${this.iconSize}`;
	}
}
