import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
	selector: '[dwaScrollToBottom]',
})
export class ScrollToBottomDirective implements OnChanges, AfterViewInit {
	private nativeElement: HTMLElement;
	@Input() public changeDetector: unknown;

	constructor(private elementRef: ElementRef) {
		this.nativeElement = this.elementRef.nativeElement;
	}

	public ngAfterViewInit(): void {
		this.nativeElement = this.elementRef.nativeElement;
		this.scrollToBottom();
	}

	public ngOnChanges(): void {
		this.nativeElement = this.elementRef.nativeElement;
		this.scrollToBottom();
	}

	private scrollToBottom(): void {
		setTimeout(() => (this.nativeElement.scrollTop = this.nativeElement.scrollHeight));
	}
}
