export class User {
	public fullName: string;
	public firstName: string;
	public lastName: string;

	constructor(firstName: string, lastName: string) {
		this.firstName = firstName;
		this.lastName = lastName;
		this.fullName = `${this.firstName} ${this.lastName}`;
	}
}

export interface IUser {
	fullName: string;
	firstName: string;
	lastName: string;
}
