import { Component, Input } from '@angular/core';

@Component({
	selector: 'dwa-menu-icon',
	templateUrl: './menu-icon.component.html',
	styleUrl: './menu-icon.component.scss',
})
export class MenuIconComponent {
	@Input() public classList: Array<string> = [];
	@Input() public color: string = '';
}
