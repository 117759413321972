import { Component, Input } from '@angular/core';

@Component({
	selector: 'dwa-user-avatar',
	templateUrl: './user-avatar.component.html',
	styleUrl: './user-avatar.component.scss',
})
export class UserAvatarComponent {
	@Input() public image?: string;
	@Input() public name!: string;
	private _initials?: string;

	private getInitials = (name: string) => {
		if (!name) return '';
		const names = name.split(' ');
		if (names.length === 1) return names[0].charAt(0);
		return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`;
	};

	public get initials(): string {
		if (!this._initials) {
			this._initials = this.getInitials(this.name);
		}
		return this._initials;
	}
}
