import { Injectable } from '@angular/core';
import { User } from '../types/user.types';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	// TODO: REMOVE DUMMY
	public static DEFAULT_FIRST_NAME = 'Spry';
	public static DEFAULT_LAST_NAME = 'FOX';

	private currentUserSubject: BehaviorSubject<User>;
	public currentUser$: Observable<User>;

	constructor() {
		this.currentUserSubject = new BehaviorSubject<User>(
			// TODO: CURRENTLY DUMMY USER LOADED FROM ENV FILES
			new User(
				environment?.systemUser?.firstName ?? UserService.DEFAULT_FIRST_NAME,
				environment?.systemUser?.lastName ?? UserService.DEFAULT_LAST_NAME
			)
		);
		this.currentUser$ = this.currentUserSubject.asObservable();
	}

	public get currentUser(): User {
		return this.currentUserSubject.value;
	}
}
