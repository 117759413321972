import EnvConfig from './env.types';

export const environment: EnvConfig = {
	production: false,
	api: {
		protocol: 'https',
		host: 'api.chatbot-dev.spryfox.de',
		port: 443,
	},
	systemUser: {
		firstName: 'Spry',
		lastName: 'Fox',
	},
	auth: {
		authenticator: 'amplify',
		amplify: {
			region: 'eu-central-1',
			userPoolId: 'eu-central-1_FcyY2uDBN',
			userPoolClientId: '73i0jbc8jdem79h4h6ptqv3srf',
			loginWith: {
				oauth: {
					domain: 'spryfox-chatbot-dev.auth.eu-central-1.amazoncognito.com',
					scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
					redirectSignIn: ['https://chatbot-dev.spryfox.de'],
					redirectSignOut: ['https://chatbot-dev.spryfox.de'],
					responseType: 'token',
				},
			},
		},
	},
};
