import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { CookieService } from './cookie.service';

@Injectable({
	providedIn: 'root',
})
export class SidebarService {
	private static SIDEBAR_COOKIE = 'sidebar-open';
	private isSidebarOpenSubject: BehaviorSubject<boolean>;
	public isSidebarOpen$: Observable<boolean>;
	private isMobileSidebarOpenSubject: BehaviorSubject<boolean>;
	public isMobileSidebarOpen$: Observable<boolean>;

	private reloadSideBar: ReplaySubject<void>;
	public reloadSideBar$: Observable<void>;

	constructor(private cookieService: CookieService) {
		const cookieValue = this.cookieService.getCookie(SidebarService.SIDEBAR_COOKIE);
		if (cookieValue === 'false') {
			this.isSidebarOpenSubject = new BehaviorSubject<boolean>(false);
		} else {
			this.isSidebarOpenSubject = new BehaviorSubject<boolean>(true);
		}
		this.isMobileSidebarOpenSubject = new BehaviorSubject<boolean>(false);
		this.isSidebarOpen$ = this.isSidebarOpenSubject.asObservable();
		this.isMobileSidebarOpen$ = this.isMobileSidebarOpenSubject.asObservable();

		this.reloadSideBar = new ReplaySubject<void>();
		this.reloadSideBar$ = this.reloadSideBar.asObservable();
	}

	public reload(): void {
		this.reloadSideBar.next();
	}

	public get isSidebarOpen(): boolean {
		return this.isSidebarOpenSubject.value;
	}

	public get isMobileSidebarOpen(): boolean {
		return this.isMobileSidebarOpenSubject.value;
	}

	public closeMobileSidebar(): void {
		this.isMobileSidebarOpenSubject.next(false);
	}

	public toggleSidebar(): void {
		this.isSidebarOpenSubject.next(!this.isSidebarOpenSubject.value);
		this.cookieService.setCookie(SidebarService.SIDEBAR_COOKIE, this.isSidebarOpenSubject.value, 30, '/');
		this.isMobileSidebarOpenSubject.next(true);
	}
}
