<svg
	[classList]="classList"
	viewBox="0 0 20 24"
	[attr.fill]="color"
	data-slot="icon"
	xmlns="http://www.w3.org/2000/svg">
	<path
		d="M22.5002 11.1749H1.5002C1.0502 11.1749 0.637695 11.5499 0.637695 12.0374C0.637695 12.4874 1.0127 12.8999 1.5002 12.8999H22.5002C22.9502 12.8999 23.3627 12.5249 23.3627 12.0374C23.3627 11.5499 22.9502 11.1749 22.5002 11.1749Z"
		[attr.fill]="color" />
	<path
		d="M22.5002 17.5499H1.5002C1.0502 17.5499 0.637695 17.9249 0.637695 18.4124C0.637695 18.8999 1.0127 19.2749 1.5002 19.2749H22.5002C22.9502 19.2749 23.3627 18.8999 23.3627 18.4124C23.3627 17.9249 22.9502 17.5499 22.5002 17.5499Z"
		[attr.fill]="color" />
	<path
		d="M1.5002 6.44998H22.5002C22.9502 6.44998 23.3627 6.07498 23.3627 5.58748C23.3627 5.09998 22.9877 4.72498 22.5002 4.72498H1.5002C1.0502 4.72498 0.637695 5.09998 0.637695 5.58748C0.637695 6.07498 1.0502 6.44998 1.5002 6.44998Z"
		[attr.fill]="color" />
</svg>
