import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
	selector: 'textarea[dwaAutosizeTextarea]',
})
export class AutosizeTextareaDirective {
	public static defaultLineHeight: number = 26;
	public static defaultPadding: number = 16;

	public height: number = AutosizeTextareaDirective.defaultLineHeight;
	public nativeElement: HTMLTextAreaElement;

	@Input() public maxRows: number = 5;
	@Output() public resized = new EventEmitter<string>();

	@HostBinding('style.height') private styleHeight: string = '41px';

	@HostListener('input', ['$event.target'])
	onInput(textArea: HTMLTextAreaElement): void {
		const neededRows = this.countBreaks(textArea.value) + 1;
		if (neededRows <= this.maxRows) {
			this.height =
				(this.countBreaks(textArea.value) + 1) * AutosizeTextareaDirective.defaultLineHeight +
				AutosizeTextareaDirective.defaultPadding;
			this.styleHeight = `${this.height}px`;
			this.resized.emit(this.styleHeight);
		}
	}

	constructor(public element: ElementRef<HTMLTextAreaElement>) {
		this.nativeElement = this.element.nativeElement;
	}

	private countBreaks(text: string): number {
		return text.split('\n').length - 1;
	}
}
